/**
 * @description Automatic date input formatting in input field
 * Like ui-mask BUT slightly different.. for pattern MM/DD/YYYY, we will auto-add separator ('/' for example) BEFORE
 * the next keystroke.
 *
 * For example - for date 01/09/1981 and keystrokes (01091981)
 * Our Directive - 0 , 01/ , 01/0, 01/09/, 01/09/1, ...
 * ui-mask - 0, 01, 01/0, 01/09, 01/09/1, ...
 *
 * See how '/' is added one step before!
 *
 *
 * improvement suggestions:
 *
 * 1. To have the template here instead of fields.html. This will allow us to properly bind to events like ng-paste
 * 2. To align to angular ngModelCtrl $render, $parsers, $validators, $formatter better.
 *    But - it is not as easy as it looks, so we can't just jump into it.
 *    I will feel more comfortable with this if we had covered the entire component with tests first.
 *    Here is a good reference: https://www.linkedin.com/pulse/all-angularjs-formatters-parsers-anuradha-bandara
 *
 *    One complication, for example - formatters are not triggered by a change to modelValue. only by direct change on the scope.
 *
 **/
(function (angular, moment, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('dateInput', function ($timeout) {
        'ngInject';
        return {
            require: '^ngModel', // ^ means reference the parent since we are using isolated scope
            scope: {},
            link: function ($scope, $element, $attrs, ngModelCtrl) {
                var {moment} = window;
                var min = $attrs.min;
                var max = $attrs.max;
                var momentFormat = $attrs.format || 'MM/DD/YYYY';
                var includeDays = momentFormat.indexOf('DD') > -1;
                var totalLength = momentFormat.length;
                var separator = momentFormat.charAt(2);

                function isNumber (char) {
                    return /[0-9]/.test(char);
                }

                function isSeparator (char) {
                    return char === separator;
                }

                function canAutoHandleInput (input) {
                    return input.length === 2 || (input.length === 5 && includeDays);
                }

                /**
         * handles following scenarios:
         *  last two characters are a number and separator ==> pads number with zero
         *  last two characters are numbers ==> adds separator
         * @param {Array<string>} args array of characters including separator for example [ '1' , '2' , '/' , '3' , '/' ]
         */
                function autoHandleInput (args) {
                    if (isNumber(args[args.length - 2]) && isSeparator(args[args.length - 1])) { // number and separator
                        args.splice(args.length - 2, 0, '0');
                    } else if (isNumber(args[args.length - 2]) && isNumber(args[args.length - 1])) { // 2 numbers
                        args.push(separator);
                    }
                    return args;
                }

                /**
         * This function does the following
         *
         *  - Adds separator if next char should be separator
         *  - Pads number with 0 on left if user neglected
         *
         *
         *  NOTE: WE CANNOT ASSUME RECENT KEYPRESS EVENT WAS AT END OF STRING! ==> Hence this function ignores the keycode
         *  Plus - in mobile keyboard, events keycodes may differ
         *
         * @param dt
         * @returns {*}
         */
                function processChange (dt, atEnd) {
                    if (!dt) {
                        return '';
                    }

                    dt = dt.replace(new RegExp(`[^\\${separator}0-9]`, 'gi'), ''); // remove illegal characters.. http://stackoverflow.com/a/21415201
                    if (atEnd && canAutoHandleInput(dt)) {
                        dt = autoHandleInput(dt.split('')).join('');
                    }

                    // if string turned out bigger than shoul dbe, truncate
                    if (dt.length > totalLength) {
                        dt = dt.slice(0, totalLength);
                    }
                    return dt;
                }

                function validateDate (dt, min, max) {
                    var momentobj = moment(dt, momentFormat);

                    if (momentobj.isValid()) {
                        min = moment(min, momentFormat).valueOf();
                        max = moment(max, momentFormat).valueOf();
                        var time = momentobj.valueOf();
                        if (time > max) {
                            return 'date max';
                        } else if (time < min) {
                            return 'date min';
                        } else {
                            return momentobj.format(momentFormat);
                        }
                    } else {
                        return 'invalid date';
                    }
                }

                var applyFormat = function (value) {
                    var momentobj = moment(value, momentFormat);
                    if (momentobj.isValid()) {
                        var newValue = momentobj.format(momentFormat);
                        $element.val(newValue);
                        ngModelCtrl.$setViewValue(newValue);
                        validateElement(newValue);

                        // Do nothing if date entered is not today
                        if (!moment().isSame(value, 'day')) {
                            return;
                        }

                        // If input is focused
                        if ($element[0] === document.activeElement) {
                            newValue.replace(' (today)', '');
                            $element.val(newValue);
                        } else {
                            newValue += ' (today)';
                            $element.val(newValue);
                        }
                    }
                };

                var handleChange = function (value) {
                    if (value) {
                        var cursorPosition = $element[0].selectionStart;
                        var atEnd = cursorPosition === value.length;
                        var newVal = processChange(value, atEnd);
                        if (value !== newVal) { // our processing's implementation currently is only valid if cursor is at the end
                            ngModelCtrl.$setViewValue(newVal);
                            ngModelCtrl.$render();
                            $element.val(newVal); // guy: strange - don't know why ngModel.$setViewValue && $render is not enough here

                            if (!atEnd) { // restore cursor position
                                $element[0].selectionStart = cursorPosition;
                                $element[0].selectionEnd = cursorPosition;
                            } else { // some devices need this. I saw this on android devices
                                // alert('setting at end' + newVal.length);
                                $timeout(function () {
                                    $element[0].selectionStart = newVal.length;
                                    $element[0].selectionEnd = newVal.length;
                                });
                            }
                        }
                    }
                };

                function setValidity (type, value) {
                    ngModelCtrl.$setValidity(type, value);
                }

                var validateElement = function (dt) {
                    if (dt && dt.length === totalLength) {
                        const validationResult = validateDate(dt, min, max);
                        setValidity('date_invalid', validationResult !== 'invalid date');
                        setValidity('date_max', validationResult !== 'date max');
                        setValidity('date_min', validationResult !== 'date min');
                    }
                };

                // This runs when we update the text field
                var oldValue = ''; // seems that in this method we need to update old value
                ngModelCtrl.$viewChangeListeners.push(() => {
                    var newValue = ngModelCtrl.$modelValue;
                    if (oldValue && newValue && newValue.length < oldValue.length) {
                        oldValue = newValue;
                        return; // do nothing
                    }
                    if (newValue.length > oldValue.length + 1) { // paste
                        applyFormat(newValue);
                    }

                    handleChange($element.val());
                    oldValue = $element.val();
                    validateElement($element.val());
                });

                $element.bind('blur', function () {
                    applyFormat($element.val());
                });

                $element.bind('focus', function () {
                    applyFormat($element.val());
                });

                // apparently this is required. inspired by: http://stackoverflow.com/a/14693201 - with comments from Ed on PR
                // this solves issue where value already exists on load, and user starts to delete the date
                ngModelCtrl.$render = function () {
                    oldValue = ngModelCtrl.$viewValue ? ngModelCtrl.$viewValue : '';

                    // Only apply formatting if we are rendering today's date
                    if (moment().isSame(ngModelCtrl.$viewValue, 'day')) {
                        applyFormat(ngModelCtrl.$viewValue);
                    } else {
                        validateElement(ngModelCtrl.$viewValue);
                        $element.val(ngModelCtrl.$viewValue);
                    }
                };

                // FOR TESTING! DON'T REMOVE!
                $scope.processChange = processChange;
            }
        };
    });
})(window.angular, window.moment, window._);
